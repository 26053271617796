import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import PaymentView, { PaymentViewWithRecaptcha } from "../components/PaymentView";
import SecurityProvider from "../components/security-provider";
import {
  payWithCreditCard,
  approveFreeOrder,
  startNewOrder,
  setDeliveryAddress,
  saveOrderIfNeeded,
  resetReorder,
  updateItemQuantity,
  removeItem,
  removeAllItems,
  startOrderItemEdit,
  setCouponsChecked,
  setChargeCardDeselected,
  resetEditItemMode,
  editItemFromPayment,
  approveCashPayment,
  setDeliveryTipPercentage,
  setDeliveryTipCustomValue,
  setOrderTip,
  setServingOptionTipPercentage,
  setServingOptionTipCustomValue,
  setChosenPaymentType,
  setFixedRemark,
  checkOrderStatus,
  setPickupTime,
  addPromoCode,
  removePromoCode,
  setServingOptionMandatoryField,
  setGiftCardsToRedeem,
  openPaymentAction
} from "../store/order/actions";
import {
  loadPaymentMethods,
  removePaymentMethod,
  resetHistoryCache,
  getExternalGiftCards,
  addExternalGiftCardToAccount,
  resetAddExternalGiftCardToAccount,
  loadLoyaltyProfile,
  showVerifyCode,
  signup,
  shouldSignUpOnPayment,
  setSignUpOnPaymentInProgress,
  openAuthSignup,
  loadGifts,
} from "../store/user/actions";
import { closeAuthModal, closeAuthView, openAuthView } from "../store/app/actions";
import * as s from "../store/selectors";
import { loadBranchAvailabiltyIfNeeded } from "../store/locations/actions";
import { isEqual, pick } from "lodash";

const PaymentPage = (props) => {
  const businessAppConfiguration =
    props.pageContext.businessAppConfiguration || {};
  const { useRecaptchaInSignUp, useRecaptchaInOrder } = businessAppConfiguration;
  const { recaptchaKey, captchaProvider, hcaptchaKey } = businessAppConfiguration;
  const loadWithSecurityProvider = recaptchaKey && (useRecaptchaInSignUp || useRecaptchaInOrder);
  
  return loadWithSecurityProvider ? (
    <SecurityProvider
      useReacptcha={loadWithSecurityProvider}
      recaptchaKey={recaptchaKey}
      captchaProvider={captchaProvider}
      hcaptchaKey={hcaptchaKey}
    >
      <PaymentViewWithRecaptcha {...props} />
    </SecurityProvider>
  ) : (
    <PaymentView {...props} />
  );
};

const getPaymentTypeDetails = (state, props) => {
  const { pageContext: { businessAppConfiguration: { useApplePay, useGooglePay } }} = props;
  const isWalletPayment = useApplePay || useGooglePay;

  const paymentTypeDetails = isWalletPayment ? s.getDefaultPaymentMethodDetails(state, props) : s.getCurrentPaymentMethodDetails(state, props);
  const paymentTypeDetailsForWallets = isWalletPayment ? s.getCurrentPaymentMethodDetails(state, props) : null;

  const isEqualPaymentType = isEqual(pick(paymentTypeDetails, ["paymentType", "paymentAppId"]), pick(paymentTypeDetailsForWallets, ["paymentType", "paymentAppId"]));

  return {
    paymentTypeDetails,
    paymentTypeDetailsForWallets: isWalletPayment && isEqualPaymentType ? null : paymentTypeDetailsForWallets,
  }
};

const mapStateToProps = (state, props) => {
  const { app, user, header, order } = state;

  return {
    app,
    order,
    checkedoutOrderItems: s.getOrderItemsForCheckout(state, props),
    checkedoutOrderItemsForPayment: s.getOrderItemsForPayment(state, props),
    priceDetails: s.getPriceDetailsForPayment(state, props),
    checkoutPriceDetails: s.getPriceDetailsForCheckout(state, props),
    servingOptionDetails: s.getServingOptionDetails(state, props),
    upsales: s.getUpsales(state, props),
    servingTime: s.getServingTime(state, props),
    hidePayment: s.shouldHidePayment(state, props),
    hideCash: s.shouldHideCash(state, props),
    isSavingOrder: s.isSavingOrder(state, props),
    orderValidationErrors: s.getOrderValidationErrors(state, props),
    locations: s.getAvailableLocations(state, props),
    selectedServingOption: s.getOrderSelectedServingOption(state, props),
    pickupTimes: s.getBranchPickupTimes(state, props),
    currentBranch: s.getOrderBranch(state, props),
    hasCouponsForCheckout: s.hasCouponsForCheckout(state, props),
    allowCashPayment: s.allowCashPayment(state, props),
    allowCreditCardPayment: s.allowCreditCardPayment(state, props),
    branchesAvailability: s.getBranchesAvailability(state, props),
    discountItems: s.getOrderDiscountItemsForCheckout(state, props),
    discountItemsForThankYou: s.getOrderDiscountItemsForPayment(state, props),
    totalExternalGiftCardRedeemAmount: s.getTotalExternalGiftCardRedeemAmount(
      state,
      props,
    ),
    appStyles: s.getStyles(state, props),
    user,
    header,
    ...getPaymentTypeDetails(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { business, servingOptions },
  } = props;

  const currency = business.openChargeCard
    ? business.openChargeCard.price.currency
    : 0;

  return {
    startNewOrder: () => dispatch(startNewOrder()),
    showVerifyCode: () => dispatch(showVerifyCode()),
    signup: (params) => dispatch(signup(params)),
    shouldSignUpOnPayment: (params) => dispatch(shouldSignUpOnPayment(params)),
    setSignUpOnPaymentInProgress: (params) => dispatch(setSignUpOnPaymentInProgress(params)),
    openPayment: (params) => dispatch(openPaymentAction({...params, currency})),
    payWithCreditCard: (
      amount,
      {
        creditCard,
        customerDetails,
        cvv,
        occiChargeAmount,
        paymentTypeDetails,
        tokenResponse,
        externalGiftCardPayments,
        recaptchaToken
      },
    ) =>
      dispatch(
        payWithCreditCard(amount, {
          creditCard,
          customerDetails,
          paymentTypeDetails,
          cvv,
          occiChargeAmount,
          currency,
          tokenResponse,
          externalGiftCardPayments,
          recaptchaToken
        }),
      ),
    setDeliveryAddress: (params) => dispatch(setDeliveryAddress(params)),
    approveFreeOrder: ({
      customerDetails,
      occiChargeAmount,
      externalGiftCardPayments,
    }) =>
      dispatch(
        approveFreeOrder({
          customerDetails,
          occiChargeAmount,
          currency,
          externalGiftCardPayments,
        }),
      ),
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    removePaymentMethod: (paymentMethodToken, { paymentTypeIdentifier }) =>
      dispatch(
        removePaymentMethod(paymentMethodToken, {
          paymentTypeIdentifier,
          currency,
        }),
      ),
    resetHistoryCache: () => dispatch(resetHistoryCache()),
    loadBranchAvailabiltyIfNeeded: () =>
      dispatch(loadBranchAvailabiltyIfNeeded()),
    saveOrderIfNeeded: (options) =>
      dispatch(saveOrderIfNeeded(servingOptions, options)),
    resetReorder: () => dispatch(resetReorder()),
    updateItemQuantity: ({ itemIndex, count }) =>
      dispatch(updateItemQuantity({ itemIndex, count })),
    removeItem: (index) => dispatch(removeItem(index)),
    removeAllItems: (params) => dispatch(removeAllItems(params)),
    startOrderItemEdit: (itemIndex, itemId, menuData) =>
      dispatch(startOrderItemEdit(itemIndex, itemId, menuData)),
    setCouponsChecked: (params) => dispatch(setCouponsChecked(params)),
    resetEditItemMode: () => dispatch(resetEditItemMode()),
    editItemFromPayment: () => dispatch(editItemFromPayment()),
    setChargeCardDeselected: (isChargeCardDeselected) =>
      dispatch(setChargeCardDeselected(isChargeCardDeselected)),
    approveCashPayment: ({
      customerDetails,
      occiChargeAmount,
      cashAmount,
      externalGiftCardPayments,
      recaptchaToken
    }) =>
      dispatch(
        approveCashPayment({
          customerDetails,
          occiChargeAmount,
          cashAmount,
          currency,
          externalGiftCardPayments,
          recaptchaToken
        }),
      ),
    setDeliveryTipPercentage: (params) =>
      dispatch(setDeliveryTipPercentage(params)),
    setDeliveryTipCustomValue: (params) =>
      dispatch(setDeliveryTipCustomValue(params)),
    setOrderTip: (params) => dispatch(setOrderTip(params)),
    setChosenPaymentType: (params) => dispatch(setChosenPaymentType(params)),
    setServingOptionTipPercentage: (params) =>
      dispatch(setServingOptionTipPercentage(params)),
    setServingOptionTipCustomValue: (params) =>
      dispatch(setServingOptionTipCustomValue(params)),
    setFixedRemark: (fixedRemark) => dispatch(setFixedRemark(fixedRemark)),
    checkOrderStatus: () => dispatch(checkOrderStatus()),
    addPromoCode: (code) => dispatch(addPromoCode(code)),
    removePromoCode: (code) => dispatch(removePromoCode(code)),
    setPickupTime: (params) => dispatch(setPickupTime(params)),
    setServingOptionMandatoryField: (params) =>
      dispatch(setServingOptionMandatoryField(params)),
    getExternalGiftCards: (branchId) =>
      dispatch(getExternalGiftCards(branchId)),
    setGiftCardsToRedeem: (params) => dispatch(setGiftCardsToRedeem(params)),
    openAuthSignup: () => dispatch(openAuthSignup()),
    openAuthView: (loginType) => dispatch(openAuthView(loginType)),
    closeAuthView: (params) => dispatch(closeAuthView(params)),
    closeAuthModal: (params) => dispatch(closeAuthModal(params)),
    resetAddExternalGiftCardToAccount: () =>
      dispatch(resetAddExternalGiftCardToAccount()),
    addExternalGiftCardToAccount: ({ cardNumber, cardPIN, recaptchaToken }) =>
      dispatch(addExternalGiftCardToAccount({ cardNumber, cardPIN, recaptchaToken })),
    loadLoyaltyProfile: (force) => dispatch(loadLoyaltyProfile(force)),
    loadGifts: () => dispatch(loadGifts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(PaymentPage));
